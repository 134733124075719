import React from 'react'
import dr from '../images/nr3.jpg';
import dr2 from '../img/gal3.jpg';
import cp from '../img/cp.jpg';

import review from '../img/review.jpg';
import gogle from '../img/google.png';
import Footer from '../component/Footer';

const Clinic = () => {
    return (
        <>


            <div className='heading'>
                <h1>ABOUT US </h1>
                <p>Home / About Us </p>
            </div>

            <div className='about'>

                <div className='about1 '>
                    <img src={dr} />
                </div>
                <div className='about2'>
                    <h3>Meet Our Consultant</h3>
                    <h1><b>Dr. Neerja Singh</b></h1>
                    <h1>Obstetrician, Gynecologist,  & Infertility</h1>
                    <h1>M.B.B.S , D.G.O. (P.G.D.H.A. )
                    </h1>
                    <br />
                    <p style={{ fontFamily: 'Arial, sans-serif', fontSize: '16px', lineHeight: '1.5' }}>
                        <b style={{ fontWeight: 'bold' }}>30 + Years</b> Of Experience
                    </p>
                    <p>In Service of Comprehensive women health for more than 3 decades</p>
                    <p>Believes in Providing Safe evidence based, effective, patient centric, timely efficient and equitable health care</p>
                    <p>It believes in Consistency and continuity of care.
                        </p>


                    <a href='Tel:7081000958'><button>CALL NOW </button></a>

                </div>

            </div>

           
            <br />
            <hr />

            <div className='about'>

                <div className='about1 '>
                    <img src={cp} />
                </div>
                <div className='about2'>
                    <h3>Meet Our Consultant</h3>
                    <h1><b>DR. R.C. SINGH</b></h1>
                    <h1>M.B.B.S., M.S.</h1>



                    <h1>SENIOR CONSULTANT SURGEON RML Hospital & BRD (CIVIL) Hespital, Lucknow
                    </h1>
                    <br />
                    <p>All types of surgical consultations.</p>
                    <p>Hydrocele and Hernia surgeries.</p>
                    <p>Anorectal problems like piles, fissure, fistula, etc.</p>
                    <p>Male infertility and sexual problems related consultation.</p>
                    <p>Breast tumours and Appendicitis.</p>
                    <p>Gall bladder stones.</p>
                    <p>Kidney and urinary bladder stones.</p>
                    <p>General medical and other surgical procedures consultation.</p>
                   





                </div>

            </div>




            <br />
            <hr />

            <br />
            <Footer />
        </>
    )
}

export default Clinic